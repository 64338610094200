<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Reporte de solicitudes realizadas</h1>
        </div>
        <br>
        <form v-on:submit.prevent="searchItems(item)">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Dependencia:</label>
                <select v-model="item.dep_id" class="form-control col-md-8">
                  <option v-for="i in dependencias" v-bind:value="i._id">
                    {{ i.dep_nmbre }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Fecha de Radicación (desde) (*):</label>
                <input type="date" class="form-control col-md-8" v-model="item.dcm_fcharadini" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Fecha de Radicación (hasta) (*):</label>
                <input type="date" class="form-control col-md-8" v-model="item.dcm_fcharadfin" required>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Incluir sólo Tipos de Pqrs:</label>
                <input type="checkbox" v-model="item.pqrs" class="form-control col-md-8">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Sólo expedientes nuevos:</label>
                <input type="checkbox" v-model="item.nuevos" class="form-control col-md-8">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group row">
                <label class="col-md-4 col-form-label">Incluir documentos relacionados:</label>
                <input type="checkbox" v-model="item.relacionados" class="form-control col-md-8">
              </div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-6">
              <button type="submit" class="btn btn-primary">Consultar</button>
            </div>
            <div class="col-6">
              <button type="text" class="btn btn-secondary" v-on:click="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import moment from 'moment';

  export default {
    data(){
      return{
        item: {},
        dependencias: [],
        message: '',
        debug: ''
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {
      this.fetchDependencia();
    },
    mounted: function(){

    },
    methods: {
      fetchDependencia()
      {
        let uri = '/deps/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          var list = response.data;
          list.sort(function(a,b){
            if (a.dep_nmbre > b.dep_nmbre) { return 1; };
            if (a.dep_nmbre < b.dep_nmbre) { return -1; };
            return 0;
          });
          this.dependencias = list;
        });
      },
      searchItems(q){
        var p = {};
        p.query = q;

        let uri = '/pqrs/clase';
        this.axios.post(uri, { cmp_id: String(this.$store.state.company) })
        .then((response) => {
            p.query.tcr_id = String(response.data.tcr_id);
            this.$router.replace({ name: 'ReportPqrs', params: p });
        })
        .catch((err) => {
            this.message = 'No se ha encontrado la clase de documento predefinida para la Pqrs.'
        });
      },
      cancel(){
        this.$router.replace({ name: 'Home' });
      }

    } //END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
